@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
    /* monospace; */
    font-family: 'Manrope', sans-serif;
}

@media only screen and (max-width: 479px) {
  .mobile-hide{ display: none !important; }
}

@media only screen and (max-width: 354px) {
  /* .search-bar{ 
    border-width: 0;
    background-color: #f7f7f7;
    min-width: 100px;
    text-align: center;
    border-radius: 25px;
  } */
  .search-icon{ 
    display: none !important;
  }
}

@media only screen and (max-width: 280px) {
  .search-bar{ 
    max-width: 170px;
  }

}

.search-bar{ 
  border-width: 0;
  background-color: #f7f7f7;
  min-width: 100px;
  text-align: center;
  border-radius: 25px;
}

@media only screen and (max-width: 1000px) {
  .mobile-hide{ display: none !important; }
  
  .coming-soon-img{ 
    max-width: 250px; 
    max-height: 153px;
    margin-bottom: 32px;
   }
}

.icon-header {
  width: 48px;
  height: 48px;
}

@media only screen and (min-width: 1000px) {
  .mobile-only{ display: none !important; }
}

/* 
@media only screen and (min-width: 676px) {
  .mobile-only{ display: none !important; }
} */

.menu-icon { 
  margin-left: auto; 
}

.coming-soon-img {
  max-width: 350px; 
  max-height: 215px;
  margin-bottom: 32px;
}



